h1 {
  @extend h1, .mb-4;
  font-weight: 300;
}

h2 {
  @extend h2, .mb-3;
  font-weight: 300;
}

h3 {
  @extend h3, .mb-2;
  font-weight: 300;
}

h4 {
  @extend h4, .mb-1;
  font-weight: 300;
}

