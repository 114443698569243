// Body
$body-bg: #fff !default;
//$primary:         #37305C !default;
$primary: #101 !default;
$body-color: $primary !default;
$text-background: #141414 !default;
//$hover-color: rgb(130, 150, 180, 1);
$hover-color: #74bccc;
$main-text-color: #345;
//$post-container-background: rgb(245, 245, 255);
//$post-container-background: rgb(210, 215, 225, 0.3);
$router-link-background-color: rgb(130, 150, 180, 0.15);
$post-container-background: #fff;

// Define the RGB values for your custom color
$primary-rgb: 55, 48, 92;

// Typography
// _variables.scss
$font-family-sans-serif: Montserrat, sans-serif !default;
$font-family-monospace: DMMono, monospace !default;
//$font-size-base: 1rem;
$line-height-base: 1.6;
$font-size-code: 0.8rem;

$enable-rfs: true !default;
//$enable-rounded: false !default;
$enable-shadows: false !default;

//$container-max-widths: (xxl: 1500px) !default;
//$grid-breakpoints: (
//  xs: 0,
//  sm: 576px,
//  md: 768px,
//  lg: 992px,
//  xl: 1200px,
//  xxl: 1560px
//) !default;


$navbar-dark-color: rgb(255, 255, 255, 0.9);
$navbar-dark-hover-color: $hover-color;

$light-bg: #edeff4 !default;


/**** Border Radius ****/
$border-radius-sm:            .25rem;
$border-radius:               .5rem;
$border-radius-lg:            .75rem;
$border-radius-xl:            1rem;
$border-radius-xxl:           1.25rem;
$border-radius-xxxl:           2rem;

.rounded-6 {
  border-radius: $border-radius-xxxl;
}

$btn-border-radius: 10px;
$btn-border-radius-sm: 8px;
$btn-border-radius-lg: 12px;
