// Remove borders and lines from Bootstrap modals
.modal-content,
.modal-header,
.modal-body,
.modal-footer {
  border: none; // Removes borders
  box-shadow: none; // Removes any shadow that might look like a border
}

.modal-header {
  border-bottom: none; // Specific for removing the bottom border in the modal header
}

.modal-footer {
  border-top: none; // Specific for removing the top border in the modal footer
}

