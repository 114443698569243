@font-face {
  font-family: 'MuktaMahee';
  src: url('../fonts/MuktaMahee-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MuktaMahee';
  src: url('../fonts/MuktaMahee-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'MuktaMahee';
  src: url('../fonts/MuktaMahee-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MuktaMahee';
  src: url('../fonts/MuktaMahee-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuktaMahee';
  src: url('../fonts/MuktaMahee-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MuktaMahee';
  src: url('../fonts/MuktaMahee-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuktaMahee';
  src: url('../fonts/MuktaMahee-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'DMMono';
  src: url('../fonts/DMMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

// highlight.js
@import "code_highlight";
//@import 'highlight.js/scss/base16/black-metal-immortal';
//@import 'highlight.js/scss/stackoverflow-dark';

[data-bs-theme=dark] {
  //  color-scheme: dark;
  //  --bs-body-color: #dee2e6;
  //  --bs-body-color-rgb: 222, 226, 230;
  //  //--bs-body-bg: #212529;
  //  --bs-body-bg: #2e2e2f;
  //--bs-body-bg: #000;
  --bs-body-bg: #{$text-background};
  //--bs-body-bg: #141414;
  //  --bs-body-bg-rgb: 33, 37, 41;
  //  --bs-emphasis-color: #fff;
  //  --bs-emphasis-color-rgb: 255, 255, 255;
  //  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  //  --bs-secondary-color-rgb: 222, 226, 230;
  //  --bs-secondary-bg: #343a40;
  //  --bs-secondary-bg-rgb: 52, 58, 64;
  //  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  //  --bs-tertiary-color-rgb: 222, 226, 230;
  //  --bs-tertiary-bg: #2b3035;
  //  --bs-tertiary-bg-rgb: 43, 48, 53;
  //  --bs-primary-text-emphasis: #6ea8fe;
  //  --bs-secondary-text-emphasis: #a7acb1;
  //  --bs-success-text-emphasis: #75b798;
  //  --bs-info-text-emphasis: #6edff6;
  //  --bs-warning-text-emphasis: #ffda6a;
  //  --bs-danger-text-emphasis: #ea868f;
  //  --bs-light-text-emphasis: #f8f9fa;
  //  --bs-dark-text-emphasis: #dee2e6;
  //  --bs-primary-bg-subtle: #031633;
  //  --bs-secondary-bg-subtle: #161719;
  //  --bs-success-bg-subtle: #051b11;
  //  --bs-info-bg-subtle: #032830;
  //  --bs-warning-bg-subtle: #332701;
  //  --bs-danger-bg-subtle: #2c0b0e;
  //  --bs-light-bg-subtle: #343a40;
  //  --bs-dark-bg-subtle: #1a1d20;
  //  --bs-primary-border-subtle: #084298;
  //  --bs-secondary-border-subtle: #41464b;
  //  --bs-success-border-subtle: #0f5132;
  //  --bs-info-border-subtle: #087990;
  //  --bs-warning-border-subtle: #997404;
  //  --bs-danger-border-subtle: #842029;
  //  --bs-light-border-subtle: #495057;
  //  --bs-dark-border-subtle: #343a40;
  //  --bs-heading-color: inherit;
  //--bs-link-color: #6ea8fe;
  //--bs-link-hover-color: #8bb9fe;
  //  --bs-link-color: #6ea8fe;
  //  --bs-link-hover-color: #8bb9fe;
  //  --bs-link-color-rgb: 110, 168, 254;
  //  --bs-link-hover-color-rgb: 139, 185, 254;
  //  --bs-code-color: #e685b5;
  //  --bs-highlight-color: #dee2e6;
  //  --bs-highlight-bg: #664d03;
  //  --bs-border-color: #495057;
  //  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  //  --bs-form-valid-color: #75b798;
  //  --bs-form-valid-border-color: #75b798;
  //  --bs-form-invalid-color: #ea868f;
  //  --bs-form-invalid-border-color: #ea868f;
}

@import 'bootstrap_overrides';
@import "icons_fonts";
@import "text_styles";
@import '../fonts/fontawesome/css/all';

:root {
  --primary: #{$primary}; // Interpolating SCSS variable into CSS variable
  --hover-color: #{$hover-color}; // Interpolating SCSS variable into CSS variable
}

body, html {
  margin: 0;
  // intentionally doubled to support old browsers that do not know about dvh
  height: 100vh;
  //https://www.youtube.com/watch?v=pOuE9sgK9jY
  height: 100dvh;
  overflow: hidden;
}

// Custom font sizes for different breakpoints
$font-size-base: 1rem; // Default font size (16px)
$font-size-sm: 0.75rem; // Small screens
$font-size-md: 0.825rem; // Medium screens
$font-size-lg: $font-size-base; // Large screens
$font-size-xl: 1.25rem; // Extra large screens
$font-size-xxl: 1.375rem; // XXL screens

// Define font size scaling across breakpoints using Bootstrap breakpoints
@each $breakpoint, $value in $grid-breakpoints {
  @if $breakpoint == sm {
    body {
      font-size: $font-size-sm;
    }
    //audio {
    //  font-size: $font-size-sm;
    //}
  }
  @if $breakpoint == md {
    @media (min-width: $value) {
      body {
        font-size: $font-size-md;
      }
      //audio {
      //  font-size: $font-size-md;
      //}
    }
  }
  @if $breakpoint == lg {
    @media (min-width: $value) {
      body {
        font-size: $font-size-lg;
      }
      //audio{
      //  font-size: $font-size-lg;
      //}
    }
  }
  //@if $breakpoint == xl {
  //  @media (min-width: $value) {
  //    body {
  //      font-size: $font-size-xl;
  //    }
  //  }
  //}
  //@if $breakpoint == xxl {
  //  @media (min-width: $value) {
  //    body {
  //      font-size: $font-size-xxl;
  //    }
  //  }
  //}
}

.btn {
  font-size: inherit;
}

//#outer {
//  height: 100vh;
//}

nav {
  //max-height: 5vh;
  //max-height: 7vh;
}

.nav-link-active {
  color: var(--bs-nav-link-hover-color);
}

main {
  //overflow-y: hidden;
  // Avoid to being able to move thins sideways, especially on mobil. Actually not happy since it would not be possible
  // if the viewport did fit, what it obviously doesn't. Just remove this, to see what I mean.
  //overflow-x: hidden;
  //min-height: 93vh;
  //-webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices
}


/**** SCROLL BAR BEHAVIOUR ****/
/* Hide scrollbar for IE, Edge, and Firefox */
* {
  -ms-overflow-style: none; /* IE */
  scrollbar-width: thin; /* Firefox & Chrome - Will override ::-webkit... in Chrome */
  scrollbar-color: transparent transparent; /* Firefox & Chrome - Will override ::-webkit... in Chrome */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 5px;

  &:hover {
    border: none;
  }
}

.show-scroll-bar {
  &::-webkit-scrollbar-thumb {
    background-color: #b1b1b1;
  }

  -ms-overflow-style: auto; /* IE */
  scrollbar-color: #b1b1b1 transparent;
}


canvas {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.text-body {
  //font-size: 1rem;
}

//todo: the following are more of a hack, to allow scrolling in only the main-content part
header {
  //max-height: 8vh;
}

footer {
  //max-height: 0vh;
}

.content {
  //max-height: 91.8vh;
}

.sidenav {
  //position: page;
}

.main-content {
  //max-height: 1000px;
  //overflow-y: auto;
  //overflow-x: hidden;
  color: $main-text-color
}

p {
  span {
  }
}

.gray {
  color: gray;
}

a {
  color: $main-text-color;
  text-decoration: none;
  //font-family: $font-family-monospace;

  &:hover {
    //color: #99b;
    color: #678;
    //text-decoration: underline;
  }
}

button {
  font-family: $font-family-monospace !important;
}

//.vanta-body-background {
//  position: absolute;
//  height: 100vh;
//  top: 0; // Align to the top of the viewport
//  left: 0; // Align to the left of the viewport
//  width: 100%; // Cover the full width
//  z-index: -1; // Ensure it's behind other content
//  //background-size: cover; // Ensure the background covers the fixed area
//}

header, footer {
  flex-shrink: 0; // Prevent the header and footer from shrinking
  font-family: $font-family-sans-serif;
  // Add any specific height definitions here if needed
}

// make background color apply at inner nodes margins (no collapse). Maybe only apply to .text-area ?!
.text-area::before {
  content: "";
  display: table; /* or block depending on your needs */
}

// make background color apply at inner nodes margins (no collapse). Maybe only apply to .text-area ?!
.text-area::after {
  content: "";
  display: table; /* or block depending on your needs */
}

.text-area {
  //background-color: rgba(30, 30, 30, 1);
  background-color: var(--bs-body-bg);
  //background-color: #141414;
  //padding: 0.8rem 0rem 0.8rem 0rem;
}

pre code {
  font-size: $font-size-code;
  overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
pre code::-webkit-scrollbar,
pre ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
pre code {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

p code {
  //font-size: $font-size-base;
  color: gray;
  //color: $primary;
}

//.card-header {
//  font-family: $font-family-monospace;
//  text-transform: uppercase;
//}

.modal-xxl {
  --bs-modal-width: 100vh;
}

.logo {
  max-width: 100%; /* Adjust logo to container width */
  max-height: 100%; /* Adjust logo to container height */
  width: auto;
  height: auto;
}

.ruler {
  color: $hover-color;
}

.post-container {
  //  background-color: $post-container-background;
}

.content-box {
  background-color: $router-link-background-color;
}

.router-link {
  cursor: pointer;
  background-color: $router-link-background-color;
}

//.on-page-introduction {
//  max-height: 55rem;
//  overflow-y: auto;
//}

//.mh-0 {
//  min-height: 0;
//}
//
//.flex-host {
//  @extend .d-flex, .flex-column, .mh-0;
//  flex: 1 1 auto;
//}


// Define responsive margins using viewport height (vh) and Bootstrap breakpoints
$margin-base: 0vh; // Default margin for smaller screens
$margin-sm: 0vh; // Small screens
$margin-md: 0vh; // Medium screens
$margin-lg: 2vh; // Large screens
$margin-xl: 4vh; // Extra large screens
$margin-xxl: 6vh; // XXL screens

// Use Bootstrap's predefined breakpoints
.video-margin {
  margin: $margin-base;

  @include media-breakpoint-up(sm) {
    margin: $margin-sm;
  }

  @include media-breakpoint-up(md) {
    margin: $margin-md;
  }

  @include media-breakpoint-up(lg) {
    margin: $margin-lg;
  }

  @include media-breakpoint-up(xl) {
    margin: $margin-xl;
  }

  @include media-breakpoint-up(xxl) {
    margin: $margin-xxl;
  }
}

.video-padding {
  padding: $margin-base;

  @include media-breakpoint-up(sm) {
    padding: $margin-sm;
  }

  @include media-breakpoint-up(md) {
    padding: $margin-md;
  }

  @include media-breakpoint-up(lg) {
    padding: $margin-lg;
  }

  @include media-breakpoint-up(xl) {
    padding: $margin-xl;
  }

  @include media-breakpoint-up(xxl) {
    padding: $margin-xxl;
  }
}

@media (max-width: 476px) {
  audio::-webkit-media-controls-current-time-display {
    display: block;
  }
  audio::-webkit-media-controls-time-remaining-display {
    display: none; // Hide playtime
  }
}

//.container {
//  overflow-wrap: break-word; /* Break long words */
//  //word-wrap: break-word; /* Legacy property, but still useful */
//  //word-break: break-word; /* Fallback for older browsers */
//}


.rainbow-text {
  //background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  //background-image: linear-gradient(to left, blue, green, yellow, red);
  background-image: linear-gradient(to bottom right, violet, indigo, blue, green, yellow, orange, red);
  //background-image: linear-gradient(in hsl longer hue 90deg, red 0 0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  //&:hover {
  //  background-image: linear-gradient(to left, indigo, blue, green, yellow, orange, red);
  //}
}

.rainbow-text-sparse {
  background-image: linear-gradient(to bottom right, blue, green, yellow, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight-text-sparse {
  background-image: linear-gradient(to bottom right, var(--hover-color), white, var(--hover-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &:hover {
    background-image: linear-gradient(to bottom right,white, var(--hover-color), white);
  }
}

.rainbow-text-inverted {
  background-image: linear-gradient(to bottom right, red, orange, yellow, green, blue, indigo, violet);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rainbow-border {
  border: double 2px transparent;
  border-radius: 30px;
  background-image: linear-gradient(var(--primary), var(--primary)),
  linear-gradient(to bottom right, violet, indigo, blue, green, yellow, orange, red);

  background-origin: border-box;
  background-clip: content-box, border-box;
  color: white;

  &:hover {
    color: #110011;

    background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, blue, green, yellow, red);
  }
}

.highlight-border {
  border: double 1px transparent;
  border-radius: 30px;
  background-image: linear-gradient(var(--primary), var(--primary)),
  linear-gradient(to bottom right, var(--hover-color), white, var(--hover-color));

  background-origin: border-box;
  background-clip: content-box, border-box;
  color: white;

  &:hover {
    //color: #110011;
    color: var(--hover-color);

    background-image: linear-gradient(var(--primary), var(--primary)),
    //background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, white, var(--hover-color), white);
  }
}
